<template>
  <div id="hours-available">
    <div class="hours-from-to">
      <input-mask
        class="hours-input-field mr-2"
        v-model="bot.availability.weekHours[day].start"
        mask="99:99"
        maskChar=" "
        :placeholder="
          lang.botMaker.addBot.botAvailability.dayFrom[languageSelected]
        "
      ></input-mask>
      <strong class="hours-divider">-</strong>
      <input-mask
        v-model="bot.availability.weekHours[day].end"
        class="hours-input-field ml-2"
        mask="99:99"
        maskChar=" "
        :placeholder="
          lang.botMaker.addBot.botAvailability.dayTo[languageSelected]
        "
      ></input-mask>
    </div>
    <kona-alert v-show="validations.availability.hoursIncorrect">
      {{
        lang.botMaker.addBot.botAvailability.daysAvailabilityError
          .incorrectTime[languageSelected]
      }}
    </kona-alert>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HoursAvailableByDay',
  props: {
    day: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations'])
  }
}
</script>

<style lang="scss" scoped>
#hours-available {
  .hours-from-to {
    display: flex;
    align-items: center;
    .hours-input-field {
      height: 38px;
      padding: 0.7rem;
      font-size: 1rem;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
